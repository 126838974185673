import React, { useState } from 'react';

import 'firebase/compat/auth';
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';

const App = () => {
  const [token, setToken] = useState('');
  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  const handleLogin = () => {
    alert('Going to Login');
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log('result', result);
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        console.log('token', token);
        // The signed-in user info.
        const user = result.user;

        console.log('user', user);
        setToken(user.accessToken);
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        console.log('error', error);
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  return (
    <div>
      <code>{token}</code>
      <br />
      <br />
      <button onClick={handleLogin}>Login</button>
    </div>
  );
};

export default App;
